import CmsManagedImage from "components/cms/cmsManagedImage/cmsManagedImage";
import { useTranslation } from "next-i18next";
import Link from "next/link";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { globalSettings } from "services/globalSettings/globalSettingsService";
import { getTargetValue, optimizedImage, sanitizeLink } from "utils/util";

export default function SocialMediaList(props) {
  const editView = useSelector((state) => state.cms.editView);
  const SocialMediaTitleTag =
    (globalSettings.footer.socialMediaTitleType?.startsWith("h")
      ? globalSettings.footer.socialMediaTitleType
      : "p") || "p";
  const { t: tCms } = useTranslation("cms");
  return (
    <>
      {props.socialMediaList &&
      (props.socialMediaList.title ||
        props.socialMediaList.content.length > 0) ? (
        <>
          {props.socialMediaList.title && (
            <Row className="edge-to-edge-row">
              <Col>
                <SocialMediaTitleTag className="social-media-list-title">
                  {props.socialMediaList.title}
                </SocialMediaTitleTag>
              </Col>
            </Row>
          )}
          <Row className="edge-to-edge-row">
            {props.socialMediaList.content.length > 0 &&
              props.socialMediaList.content.map((element, index) => {
                return (
                  <React.Fragment key={index}>
                    {element.mimg ? (
                      <Col className="col-auto">
                        <div className="social-media-img">
                          <Link
                            href={
                              element.linkUrl
                                ? `${sanitizeLink(element.linkUrl)}${
                                    element.linkGetParams
                                      ? element.linkGetParams
                                      : ""
                                  }`
                                : "#"
                            }
                            passHref
                          >
                            <a
                              target={
                                element.linkTarget
                                  ? element.linkTarget
                                  : getTargetValue(
                                      sanitizeLink(element.linkUrl) || "#"
                                    )
                              }
                              hrefLang={
                                element.linkHrefLang
                                  ? element.linkHrefLang
                                  : null
                              }
                              rel={element.linkRel ? element.linkRel : null}
                            >
                              <CmsManagedImage
                                width={50}
                                height={50}
                                img={optimizedImage(element.mimg, true, 200)}
                              />
                            </a>
                          </Link>
                        </div>
                      </Col>
                    ) : null}
                  </React.Fragment>
                );
              })}
          </Row>
        </>
      ) : (
        <>
          {editView && (
            <Row className="edge-to-edge-row">
              <Col xs={12}>
                <div className="social-media-placeholder">
                  {tCms("createSocialMediaContents")}
                </div>
              </Col>
            </Row>
          )}
        </>
      )}
      {/* Dynamic style*/}
      <style jsx>{`
        .social-media-placeholder {
          padding-top: 12px;
          padding-bottom: 12px;
          text-align: center;
        }

        .social-media-list-title {
          hyphens: auto;
          @media (min-width: ${globalSettings.responsive.breakpointdesktop}px) {
            word-break: break-word;
            hyphens: none;
          }
        }

        .social-media-img {
          margin-top: 25px;
          :hover {
            cursor: pointer;
            opacity: 0.7;
          }
        }
      `}</style>
    </>
  );
}
