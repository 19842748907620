import dynamic from "next/dynamic";
import {
  icon_fa_angle_down,
  icon_fa_angle_left,
  icon_fa_angle_right,
  icon_fa_angle_up,
  icon_fa_clone,
  icon_fa_gear,
  icon_fa_pen,
  icon_fa_plus,
  icon_fa_trash,
} from "assets/svgs/fontawesomeSvgs";
const CmsIconButton = dynamic(() =>
  import("components/cms/contentManagerControls/cmsIconButton/cmsIconButton")
);
import { useTranslation } from "next-i18next";
import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { showAddCEModal } from "store/actions";
import { cmsGeneralConfigFrameStyle } from "./cmsGeneralConfigFrameStyle";

export default function CmsGeneralConfigFrame({
  children,
  title,
  openContentEditModal,
  openConfigModal,
  onDelete,
  moveUp,
  moveDown,
  position,
  arrayLength,
  frameOnHover,
  buttonsRight,
  configButtonTitle,
  showFrameWithoutEditMode,
  smallTitle,
  cloneContentElement,
  showInsertButton,
  moveDirection,
}) {
  const { t: tCms } = useTranslation("cms");
  const style = useMemo(() => cmsGeneralConfigFrameStyle(), []);
  const editView = useSelector((state) => state.cms.editView);
  const dispatch = useDispatch();

  const openAddCEModal = () => {
    dispatch(showAddCEModal(position));
  };

  return (
    <>
      {editView || showFrameWithoutEditMode ? (
        <div
          className={`${frameOnHover ? "frame-on-hover " : " "}${
            children?.type.name === "Navbar"
              ? "config-frame-mobile-position-navbar "
              : " "
          }general-config-frame`}
        >
          <div className="frame-bar">
            <div className="left-controls">
              {buttonsRight ? null : (
                <>
                  {moveUp &&
                    (moveDirection && moveDirection === "horizontal" ? (
                      <CmsIconButton
                        disabled={position === 0}
                        onClick={() => moveUp()}
                        icon={<>{icon_fa_angle_left("svg-fill-white", 20)}</>}
                        title={tCms("moveUp")}
                      />
                    ) : (
                      <CmsIconButton
                        disabled={position === 0}
                        onClick={() => moveUp()}
                        icon={<>{icon_fa_angle_up("svg-fill-white", 20)}</>}
                        title={tCms("moveUp")}
                      />
                    ))}
                  {moveDown &&
                    (moveDirection && moveDirection === "horizontal" ? (
                      <CmsIconButton
                        disabled={position === arrayLength - 1}
                        onClick={() => moveDown()}
                        icon={<>{icon_fa_angle_right("svg-fill-white", 20)}</>}
                        title={tCms("moveDown")}
                      />
                    ) : (
                      <CmsIconButton
                        disabled={position === arrayLength - 1}
                        onClick={() => moveDown()}
                        icon={<>{icon_fa_angle_down("svg-fill-white", 20)}</>}
                        title={tCms("moveDown")}
                      />
                    ))}
                </>
              )}
            </div>
            <div className={`title ${smallTitle ? `small-title ` : ``}`}>
              {title}
            </div>
            <div className="right-controls">
              {buttonsRight ? (
                <>
                  {moveUp && (
                    <CmsIconButton
                      disabled={position === 0}
                      onClick={() => moveUp()}
                      icon={<>{icon_fa_angle_up("svg-fill-white", 20)}</>}
                      title={tCms("moveUp")}
                    />
                  )}
                  {moveDown && (
                    <CmsIconButton
                      disabled={position === arrayLength - 1}
                      onClick={() => moveDown()}
                      icon={<>{icon_fa_angle_down("svg-fill-white", 20)}</>}
                      title={tCms("moveDown")}
                    />
                  )}
                </>
              ) : null}
              {showInsertButton && (
                <CmsIconButton
                  icon={<>{icon_fa_plus("svg-fill-white", 20)}</>}
                  onClick={() => openAddCEModal()}
                  title={tCms("contentFrame-insert-ce")}
                />
              )}
              {cloneContentElement && (
                <CmsIconButton
                  icon={<>{icon_fa_clone("svg-fill-white", 20)}</>}
                  onClick={() => cloneContentElement()}
                  title={tCms("contentFrame-clone-ce")}
                />
              )}
              {openContentEditModal && (
                <CmsIconButton
                  onClick={() => openContentEditModal()}
                  icon={<>{icon_fa_pen("svg-fill-white", 20)}</>}
                  title={configButtonTitle || tCms("settings")}
                />
              )}
              {onDelete && (
                <CmsIconButton
                  onClick={() => onDelete()}
                  icon={<>{icon_fa_trash("svg-fill-white", 18)}</>}
                  title={tCms("deleteElement")}
                />
              )}
              {openConfigModal && (
                <CmsIconButton
                  onClick={() => openConfigModal()}
                  icon={<>{icon_fa_gear("svg-fill-white", 20)}</>}
                  title={tCms("settings")}
                />
              )}
            </div>
          </div>
          {children}
        </div>
      ) : (
        <>{children}</>
      )}
      <style jsx>{style}</style>
    </>
  );
}
