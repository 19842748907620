import dynamic from "next/dynamic";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import { useRef } from "react";
import { Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { globalSettings } from "services/globalSettings/globalSettingsService";
import { openDynamicListEditor } from "store/actions";
import FooterContent from "./footerContent";
import FooterSpace from "./footerSpace";
import { FooterStyle } from "./footerStyle";

const CmsGeneralConfigFrame = dynamic(() =>
  import(
    "components/cms/contentManagerControls/cmsGeneralConfigFrame/cmsGeneralConfigFrame"
  )
);

export default function Footer() {
  const dispatch = useDispatch();
  const cmsEditView = useSelector((state) => state.cms.editView);
  const footerRef = useRef(null);
  const { t: tCms } = useTranslation("cms");
  const router = useRouter();

  const footerContent = useSelector((state) =>
    state.general.dynamicLists
      ? state.general.dynamicLists.find(
          (dynamicList) => dynamicList.name === "footer"
        )
      : []
  );

  const copyrightText = globalSettings?.footer?.copyrightTextJson;

  const footerType = globalSettings?.footer?.footertype;
  const parallaxOrStickyFooter =
    footerType === "parallax" || footerType === "sticky" ? true : false;

  // box-shadow: inset 0 4px 10px -5px; internal shadow example
  // box-shadow: 0px 4px 10px -7px; main shadow example

  return (
    <>
      <div className="footer-wrapper">
        <div
          ref={footerRef}
          className={`${
            globalSettings.footer?.spaceX === 0 ? "" : "container-fluid"
          } footer-space footer ${
            parallaxOrStickyFooter && cmsEditView
              ? "footer-sticky-edit-mode"
              : ""
          }`}
        >
          {/* is this neccessary? there is a min height for the footer of 100px*/}
          {/* {parallaxOrStickyFooter && <div className="footerPlaceholder"></div>} */}
          {/* Row */}
          <div className="row g-0 justify-content-center footer-content m-auto">
            <Col className="repeatable-elements-column">
              {cmsEditView ? (
                <CmsGeneralConfigFrame
                  smallTitle
                  configButtonTitle={tCms("process")}
                  frameOnHover
                  title={tCms("footer-content")}
                  openContentEditModal={() => {
                    dispatch(
                      openDynamicListEditor(
                        tCms("footer-element"),
                        "footer",
                        [],
                        ["richTextContent", "img", "imgAlt", "imgTitle"]
                      )
                    );
                  }}
                >
                  <FooterContent
                    copyrightText={copyrightText}
                    footerContent={footerContent}
                    cmsEditView={cmsEditView}
                    repeatableElementsAmount={
                      footerContent && footerContent.content
                        ? footerContent.content.length
                        : 1
                    }
                  />
                </CmsGeneralConfigFrame>
              ) : (
                <FooterContent
                  copyrightText={copyrightText}
                  footerContent={footerContent}
                  cmsEditView={cmsEditView}
                  repeatableElementsAmount={
                    footerContent && footerContent.content
                      ? footerContent.content.length
                      : 1
                  }
                />
              )}
            </Col>
          </div>
        </div>
        {copyrightText && (
          <div
            className={`${
              globalSettings.footer?.spaceX === 0 ? "" : "container-fluid"
            } footer-space footer-copyright`}
            dangerouslySetInnerHTML={{
              __html: copyrightText[router.locale]
                ? copyrightText[router.locale]
                : "",
            }}
          >
            {/* Row */}
            {/* Note: footerCopyrightText has to start with a row (with g-0) */}
          </div>
        )}
      </div>
      <FooterSpace settings={{ ...globalSettings.footer }} />
      <FooterStyle
        parallaxOrStickyFooter={parallaxOrStickyFooter}
        footerType={footerType}
        repeatableElementsAmount={
          footerContent && footerContent.content
            ? footerContent.content.length
            : 1
        }
        spaceX={globalSettings.footer.spaceX}
      />
      <style jsx>{`
        :global(.main) {
          ${parallaxOrStickyFooter ? `position: relative;` : ""}
        }
      `}</style>
    </>
  );
}
