import { globalSettings } from "services/globalSettings/globalSettingsService";

export default function FooterSpace({ settings }) {
  const {
    scalingfactorSpaceXMobile,
    scalingfactorSpaceXTablet,
    scalingfactorSpaceX2kplus,
  } = globalSettings.layout;

  // const spaceXrow = settings.spaceX;
  const spaceXrow = settings.spaceX - 8 < 0 ? 0 : settings.spaceX - 8; // 8, 8+ or 0
  const spaceXcontainer =
    settings.spaceX !== 0
      ? 8 - settings.spaceX > 0
        ? 8 - settings.spaceX
        : 8
      : 0; // 0, 1-7 or 8

  return (
    <style jsx global>{`
      .footer-space {
        /* Container Mobile */
        padding-left: ${spaceXcontainer *
        scalingfactorSpaceXMobile}px !important;
        padding-right: ${spaceXcontainer *
        scalingfactorSpaceXMobile}px !important;

        /* Container Tablet */
        @media (min-width: ${globalSettings.responsive.breakpointmobile}px) {
          padding-left: ${spaceXcontainer *
          scalingfactorSpaceXTablet}px !important;
          padding-right: ${spaceXcontainer *
          scalingfactorSpaceXTablet}px !important;
        }

        /* Container Desktop */
        @media (min-width: ${globalSettings.responsive.breakpointdesktop}px) {
          padding-left: ${spaceXcontainer}px !important;
          padding-right: ${spaceXcontainer}px !important;
        }

        /* Container 2k Desktop */
        @media (min-width: ${globalSettings.responsive.breakpoint2k}px) {
          padding-left: ${spaceXcontainer *
          scalingfactorSpaceX2kplus}px !important;
          padding-right: ${spaceXcontainer *
          scalingfactorSpaceX2kplus}px !important;
        }
        & > div {
          padding-left: ${spaceXrow * scalingfactorSpaceXMobile}px !important;
          padding-right: ${spaceXrow * scalingfactorSpaceXMobile}px !important;
          @media (min-width: ${globalSettings.responsive.breakpointmobile}px) {
            padding-left: ${spaceXrow * scalingfactorSpaceXTablet}px !important;
            padding-right: ${spaceXrow *
            scalingfactorSpaceXTablet}px !important;
          }
          @media (min-width: ${globalSettings.responsive.breakpointdesktop}px) {
            padding-left: ${spaceXrow}px !important;
            padding-right: ${spaceXrow}px !important;
          }
          @media (min-width: ${globalSettings.responsive.breakpoint2k}px) {
            padding-left: ${spaceXrow * scalingfactorSpaceX2kplus}px !important;
            padding-right: ${spaceXrow *
            scalingfactorSpaceX2kplus}px !important;
          }
        }
      }
    `}</style>
  );
}
