import { globalSettings } from "services/globalSettings/globalSettingsService";
import { getColorVariableName } from "utils/util";

export const FooterStyle = ({
  parallaxOrStickyFooter,
  footerType,
  repeatableElementsAmount,
  spaceX,
}) => {
  const footerSpaceTop = globalSettings?.footer?.footerSpaceTop || "0";
  const footerSpaceBottom = globalSettings?.footer?.footerSpaceBottom || "0";
  const footerBgColor = globalSettings?.footer?.footerBgColor || "#ffffff";
  const footerFontSize = globalSettings?.footer?.footerFontSize / 10 || "1.8";
  const footerBorderTop = globalSettings?.footer?.footerBorderTop || false;
  const footerBorderWidth = globalSettings?.footer?.footerBorderWidth || "3";
  const footerBorderColor =
    globalSettings?.footer?.footerBorderColor || "#000000";
  const footerFontColor = globalSettings?.footer?.footerFontColor
    ? getColorVariableName(globalSettings.footer.footerFontColor)
    : "--black";
  const repeatableElementWidth =
    globalSettings?.footer?.repeatableElementWidth || 290;
  // socialMediaWidth: Is allowed to be 0
  const socialMediaWidth =
    globalSettings?.footer?.socialMediaWidth === 0
      ? 0
      : globalSettings?.footer?.socialMediaWidth
      ? globalSettings.footer.socialMediaWidth
      : 290;

  // Defines the breakpoint for snapping of socialMedia-Element.
  // Note: Will only take into account desktop-spaceX.
  const socialMediaSnapBreakpoint =
    repeatableElementWidth * repeatableElementsAmount +
    socialMediaWidth +
    2 * spaceX;

  // Position:fixed if footer is sticky or parallax
  const footerPosition = parallaxOrStickyFooter ? "sticky" : "relative";

  const getFooterZIndex = () => {
    if (footerType === "sticky") {
      return 1010;
    } else {
      return 0;
    }
  };

  return (
    <style jsx global>
      {`
        .footer-wrapper {
          display: block;
          position: relative;
          z-index: 0;

          @media (min-width: ${globalSettings.responsive.breakpointdesktop}px) {
            position: ${footerPosition};
            z-index: ${parallaxOrStickyFooter ? getFooterZIndex() : 0};
            ${`${parallaxOrStickyFooter ? `bottom: 0;` : ""}`}
          }

          .footer {
            border-top: ${footerBorderTop
              ? `solid ${footerBorderWidth}px ${footerBorderColor}`
              : `none`};
            font-size: ${footerFontSize}rem;
            padding-top: ${footerSpaceTop}px;
            padding-bottom: 0px;
            background-color: ${footerBgColor};
            color: var(${footerFontColor});
            min-height: 100px;
            bottom: 0;
            z-index: 5;
            position: relative;
            width: 100%;
            &.footer-sticky-edit-mode {
              max-height: 60%;
              overflow-y: auto;
            }

            .footer-content {
              max-width: 100%; // was 83%
              padding-bottom: ${footerSpaceBottom}px;
            }

            .footer-content-right {
              padding-left: 15px;
            }

            .footerPlaceholder {
              height: 100px;
              z-index: 1;
            }

            .footer-copyright {
              background-color: #fff;
              color: #000;
              .row,
              .row > * {
                --bs-gutter-x: 0 !important;
              }
            }

            .custom {
              color: var(
                ${getColorVariableName(globalSettings.footer.footerFontColor)}
              );
              text-decoration: none;
              cursor: pointer;
              font-size: ${globalSettings.link.linkfontsizedesktop};
              font-weight: ${globalSettings.link.linkfontweight};
            }
          }
        }

        :global(.social-media-column) {
          @media (min-width: ${socialMediaSnapBreakpoint}px) {
            margin-left: ${globalSettings.footer.footerElementsAlignmentLeft
              ? "auto"
              : "unset"} !important;
            margin-right: ${globalSettings.footer.footerElementsAlignmentLeft
              ? "unset"
              : "unset"} !important;

            /* Small padding (like before from ql-editor) on non-snaped-Screen to 
            prevent no space between last column and socialMedia. */
            // moved padding left/right to .footer-col in footerContent.js
            // it is configurable in strapi now
            // padding-left: 15px;
            // padding-right: 15px;
          }
        }
      `}
    </style>
  );
};
