import css from "styled-jsx/css";

export const cmsGeneralConfigFrameStyle = () => {
  return css`
    .general-config-frame {
      border: solid 2px var(--cms-lightblue);
      border-radius: 5px;
      position: relative;
      width: calc(100% - 4px);
      margin-left: auto;
      margin-right: auto;
      max-width: 100%;

      /* change next 3 lines if less white space is required */
      margin-top: 2rem;
      margin-bottom: 2rem;
      padding: 0;

      &.frame-on-hover {
        border: solid 2px transparent;
        border-radius: 0;
        .frame-bar {
          .right-controls {
            opacity: 0;
          }
          .left-controls {
            opacity: 0;
          }
          .title {
            opacity: 0;
          }
        }
        &:hover,
        &:focus-within {
          border: solid 2px var(--cms-lightblue);
          border-radius: 5px;
          .frame-bar {
            pointer-events: none;
            .right-controls {
              opacity: 1;
            }
            .left-controls {
              opacity: 1;
            }
            .title {
              opacity: 1;
            }
          }
        }
      }

      .frame-bar {
        display: flex;
        justify-content: space-between;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: fit-content;
        :global(.cms-icon-button) {
          background-color: var(--cms-lightblue);
          padding: 0.3rem 1rem 0.5rem;
          pointer-events: all;
          &:first-of-type {
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
          }
          &:last-of-type {
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
          }
        }
      }

      .frame-bar .left-controls {
        display: flex;
        /* opacity: 0; */
        z-index: 990;
        top: 0;
        left: 0;
        position: absolute;
        transform: translate(10px, -50%);
      }

      .frame-bar .title {
        text-align: center;
        display: flex;
        /* opacity: 0; */
        color: #fff;
        top: 0;
        left: 50%;
        position: absolute;
        transform: translate(-50%, -50%);
        background-color: var(--cms-lightblue);
        z-index: 990;
        padding: 0.5rem 5rem;
        border-radius: 5px;

        &.small-title {
          padding: 0.5rem 0.75rem;
          white-space: nowrap;
        }
      }

      .frame-bar .right-controls {
        display: flex;
        /* opacity: 0; */
        z-index: 990;
        top: 0;
        left: 100%;
        position: absolute;
        transform: translate(calc(-100% - 10px), -50%);
      }
    }
  `;
};
