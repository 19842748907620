import CmsManagedImage from "components/cms/cmsManagedImage/cmsManagedImage";
import CmsGeneralConfigFrame from "components/cms/contentManagerControls/cmsGeneralConfigFrame/cmsGeneralConfigFrame";
import SocialMediaList from "components/socialMediaList/socialMediaList";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { globalSettings } from "services/globalSettings/globalSettingsService";
import { openDynamicListEditor } from "store/actions";
import { MOBILE_SCALING_FACTOR } from "utils/constants";
import { optimizedImage } from "utils/util";
import FooterContentStyle from "./footerContentStyle";

export default function FooterContent(props) {
  const dispatch = useDispatch();
  const router = useRouter();
  const { t: tCms } = useTranslation("cms");
  const repeatableElementWidth =
    globalSettings?.footer?.repeatableElementWidth || 290;
  const socialMediaWidth =
    globalSettings?.footer?.socialMediaWidth === 0
      ? 0
      : globalSettings?.footer?.socialMediaWidth
      ? globalSettings.footer.socialMediaWidth
      : 290;
  const footerElementsAlignment = globalSettings?.footer
    ?.footerElementsAlignmentLeft
    ? "justify-content-start"
    : "justify-content-around";

  const footerColSpaceXMobile = globalSettings?.footer?.footerColSpaceX
    ? globalSettings.footer.footerColSpaceX * MOBILE_SCALING_FACTOR + "px"
    : "0";
  const footerColSpaceXDesktop = globalSettings?.footer?.footerColSpaceX
    ? globalSettings.footer.footerColSpaceX + "px"
    : "0";
  const footerColSpaceYMobile = globalSettings?.footer?.footerColSpaceY
    ? globalSettings.footer.footerColSpaceY * MOBILE_SCALING_FACTOR + "px"
    : "0";
  const footerColSpaceYDesktop = globalSettings?.footer?.footerColSpaceY
    ? globalSettings.footer.footerColSpaceY + "px"
    : "0";

  const socialMediaList = useSelector((state) =>
    state.general.dynamicLists
      ? state.general.dynamicLists.find(
          (dynamicList) => dynamicList.name === "socialMedia"
        )
      : []
  );
  const cmsEditView = useSelector((state) => state.cms.editView);

  const renderFooter =
    (props.footerContent?.content && props.footerContent.content.length > 0) ||
    socialMediaList?.title ||
    socialMediaList?.content?.length > 0 ||
    cmsEditView
      ? true
      : false;

  return (
    <>
      <Row className={`edge-to-edge-row ${footerElementsAlignment}`}>
        {props.footerContent && props.footerContent.content.length > 0 ? (
          props.footerContent.content.map((element, index) => {
            return (
              <Col className="footer-col" key={index}>
                {element.mimg ? (
                  <Row className="edge-to-edge-row">
                    <Col>
                      <div className="footer-col-image">
                        <CmsManagedImage
                          img={optimizedImage(element.mimg, true, 400)}
                          width={167}
                          height={73}
                          alignment="left"
                        />
                      </div>
                    </Col>
                  </Row>
                ) : null}
                {element.richTextContent ? (
                  <Row className="edge-to-edge-row">
                    <Col>
                      <div
                        className="ql-editor"
                        dangerouslySetInnerHTML={{
                          __html: element.richTextContent,
                        }}
                      ></div>
                    </Col>
                  </Row>
                ) : null}
              </Col>
            );
          })
        ) : (
          <>
            {props.cmsEditView && (
              <Col xs={12}>
                <div className="placeholder-footer">
                  <p>{tCms("footer-addContent")}</p>
                </div>
              </Col>
            )}
          </>
        )}

        {/* socialMedia is now part of footerContent. Can be disabled via socialMediaWidth: 0 */}
        {socialMediaWidth === 0 ? null : (
          <Col className="social-media-column">
            {props.cmsEditView ? (
              <CmsGeneralConfigFrame
                smallTitle
                configButtonTitle={tCms("process")}
                frameOnHover
                title={tCms("footer-footerSocialMedia")}
                openContentEditModal={() => {
                  dispatch(
                    openDynamicListEditor(
                      tCms("footer-socialMediaElement"),
                      "socialMedia",
                      ["title"],
                      ["linkUrl", "img", "imgAlt", "imgTitle"]
                    )
                  );
                }}
              >
                <SocialMediaList socialMediaList={socialMediaList} />
              </CmsGeneralConfigFrame>
            ) : (
              <SocialMediaList socialMediaList={socialMediaList} />
            )}
            {/* a link for the user to reset the cookiebot */}
            {globalSettings?.cookiebot?.cookiebotEnabled && (
              <>
                {props.copyrightText &&
                props.copyrightText[router.locale] &&
                !props.copyrightText[router.locale].includes(
                  "javascript: Cookiebot.renew()"
                ) ? (
                  <Row className="edge-to-edge-row mt-2 mb-3">
                    <Col>
                      <p className="custom" onClick={() => Cookiebot.renew()}>
                        {globalSettings?.cookiebot?.cookiebotFooterLabelJson
                          ? globalSettings.cookiebot.cookiebotFooterLabelJson[
                              router.locale
                            ]
                          : ""}
                      </p>
                    </Col>
                  </Row>
                ) : null}
              </>
            )}
          </Col>
        )}
      </Row>
      <FooterContentStyle />
      {/* Dynamic style*/}
      <style jsx>{`
        :global(.footer-wrapper) {
          :global(.footer),
          :global(.footer .footer-content),
          :global(.social-media-column) {
            ${!renderFooter
              ? "min-height: 0 !important; padding-top: 0 !important; padding-bottom: 0 !important"
              : ""}
          }
        }

        :global(.footer-col) {
          min-width: ${`min(${repeatableElementWidth}px, 100%)`};
          max-width: ${repeatableElementWidth}px;

          padding-left: ${footerColSpaceXMobile};
          padding-right: ${footerColSpaceXMobile};
          padding-top: ${footerColSpaceYMobile};
          padding-bottom: ${footerColSpaceYMobile};

          @media (min-width: ${globalSettings.responsive.breakpointmobile}px) {
            padding-left: ${footerColSpaceXDesktop};
            padding-right: ${footerColSpaceXDesktop};
            padding-top: ${footerColSpaceYDesktop};
            padding-bottom: ${footerColSpaceYDesktop};
          }

          :global(.ql-editor) {
            padding: 0px !important;

            :global(p) {
              hyphens: auto;
              @media (min-width: ${globalSettings.responsive
                  .breakpointdesktop}px) {
                word-break: break-word;
                hyphens: none;
              }
            }
          }
        }

        :global(.social-media-column) {
          min-width: ${`min(${socialMediaWidth}px, 100%)`};
          max-width: ${socialMediaWidth}px;

          padding-left: ${footerColSpaceXMobile};
          padding-right: ${footerColSpaceXMobile};
          padding-top: ${footerColSpaceYMobile};
          padding-bottom: ${footerColSpaceYMobile};

          @media (min-width: ${globalSettings.responsive.breakpointmobile}px) {
            padding-left: ${footerColSpaceXDesktop};
            padding-right: ${footerColSpaceXDesktop};
            padding-top: ${footerColSpaceYDesktop};
            padding-bottom: ${footerColSpaceYDesktop};
          }

          /* Mobile/Tablet -> socialMediaSnapBreaktpoint Styling*/
          margin-right: ${footerElementsAlignment === "justify-content-start"
            ? "auto"
            : "unset"};
          margin-left: ${footerElementsAlignment === "justify-content-start"
            ? "unset"
            : "unset"};
        }

        .placeholder-footer {
          padding-top: 12px;
          padding-bottom: 12px;
          text-align: center;
        }

        // moved all paddings to footerContent .footer-col (configurable)
        // .footer-col-image {
        //   padding-left: 15px;
        // }
      `}</style>
    </>
  );
}
