import { globalSettings } from "services/globalSettings/globalSettingsService";
import { getGlobalSettingsColor } from "utils/util";
export default function FooterContentStyle() {
  return (
    <style jsx global>{`
      .footer-col {
        .ql-editor {
          p {
            a {
              color: ${getGlobalSettingsColor(
                globalSettings.footer.footerLinkFontColor
              )} !important;

              font-size: ${globalSettings.footer.footerFontSize}px;

              &:hover {
                color: ${getGlobalSettingsColor(
                  globalSettings.footer.footerLinkHoverFontColor
                )} !important;
              }
            }
          }
        }
      }
    `}</style>
  );
}
